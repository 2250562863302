import React, { useContext } from "react";
import FormRedeemTree from "../components/FormRedeemTree/FormRedeemTree";
import Intro from "../components/Intro_v2/Intro_v2";
import Meta from "../components/Meta/Meta";
import { DataContext } from "../contexts/DataContext";

const RedeemTreePage = () => {
  const { pageLabels } = useContext(DataContext);

  return (
    <>
      <Meta />
      <div style={{ padding: "20vh 0" }}>
        <Intro
          bigText={pageLabels.title}
          subtitle={pageLabels.paragraph}
        />

        <FormRedeemTree />
      </div>
    </>
  );
};

export default RedeemTreePage;
